<template>
  <div >
    <navbar-component></navbar-component>

    <main dir="rtl">
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center"
        style="min-height: 75vh;"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style='background-image: url("https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");'
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl font-Changa">

                    مزن 
                </h1>

                <p class="mt-4 text-lg text-gray-300 font-Changa">
                الجامعة العربية للتعليم المفتوح

                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px;"
        >

          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20 bg-gray-300 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i class="fas fa-award"></i>
                  </div>
                  <h6 class="text-xl font-semibold font-Changa">طلبة</h6>
                  <p class="mt-2 mb-4 text-gray-600 font-Changa">
                    قم ببناء مهاراتك العمليّة من خلال الإلتحاق ببرامج تدريبيّة متطوّرة واختصر على نفسك طريقك نحو العمل بفضل شراكتنا مع الشركات التقنية.
                    <a href ="https://www.google.com"> 
                      <strong>هنا </strong> 
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400"
                  >
                    <i class="fas fa-retweet"></i>
                  </div>
                  <h6 class="text-xl font-semibold font-Changa">أساتذة</h6>
                  <p class="mt-2 mb-4 text-gray-600 font-Changa">
                    جامعة مزن توفر لكم بيئة ملائمة للتعليم عن بعد بأحدث الطرق و التكنولوجيات.
                  </p>
                </div>
              </div>
            </div>
            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400"
                  >
                    <i class="fas fa-fingerprint"></i>
                  </div>
                  <h6 class="text-xl font-semibold font-Changa">منظمين</h6>
                  <p class="mt-2 mb-4 text-gray-600 font-Changa">
                    بمشاركتك معنا كمنظم ستكتسب الكثير من الخبرات و المهارات و تطور من مهاراتك الادارية          
                   </p>
                </div>
              </div>
            </div>
          </div>
          <div id="scrollWho" class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div 
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal font-Changa">
                الجامعة العربية للتعليم المفتوح
              </h3>
              <p
                class="text-xl font-light leading-relaxed mt-4 mb-4 text-gray-700 font-Changa"
              >
             
منصة مزن توفر تكوين عالي الجودة في تخصصات علوم الحاسوب. نسعى إلى رفع مستوى التعليم الإلكتروني في الوطن العربي و أن نكون حلقة وصل بين سوق العمل و خرّجِينا، بالإضافة لتوفير فرصة لأصحاب الشركات للحصول على مبرمجين حسب الطلب و توفير عناء البحث عنهم.
              </p>
              <p
                class="text-xl font-light leading-relaxed mt-0 mb-4 text-gray-700"
              >
               
              </p>
              <a
                href=""
                class="font-bold text-gray-800 mt-8 font-Changa"
                >إبدأ معنا !</a
              >
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600"
              >
                <img
                  alt="..."
                  src="../assets/img/annie-spratt.jpg"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px;"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-pink-600 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    إلتحق بنا 
                  </h4>
                  <p class="text-md font-light mt-2 text-white font-Changa">
تعلم بأحدث الطرق الفعالة.. لا تتأخر أكثر
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="../assets/img/erge-mahindra.jpg"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <div
                  class="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300"
                >
                  <i class="fas fa-rocket text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold font-Changa">  اطمح، تعلّم، تقدّم و احصل على وظيفة</h3>
                <p class="mt-4 text-lg leading-relaxed text-gray-600 font-Changa">
                  قم ببناء مهاراتك العمليّة من خلال الالتحاق ببرامج تدريبيّة متطوّرة، واختصر على نفسك طريقك نحو  العمل بفضل شراكتنا مع الشركات التقنية.               </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"
                          ><i class="fas fa-fingerprint"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600 font-Changa">
                          منهج تعليمي ثري
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"
                          ><i class="fab fa-html5"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600 font-Changa">أفضل الأساتذة</h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"
                          ><i class="far fa-paper-plane"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600 font-Changa">أحدث التكنولوجيات</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-20 pb-48 font-Changa">
        <div class="container mx-auto px-4">
          <div id="scrollTeam" class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">فريق منصة مزن </h2>
              <p class="text-lg leading-relaxed m-4 text-gray-600">
                فريق ذو مهارات عالية اتخذ فكرا لنصر امته 
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/women.png"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">سمية مدية</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    مؤسسة مزن
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                     
                      onclick="location.href='https://www.linkedin.com/in/soumia-media/'"
                    >
                      <i class="fab fa-linkedin"></i></button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/women.png"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">مرابط أسماء</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    مسؤولة الموارد البشرية 
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      
                      onclick="location.href='https://www.linkedin.com/in/asma-merabet-3b799818b/'"

                    >
                      <i class="fab fa-linkedin"></i></button
                    >
                  </div>
                </div>
              </div>
            </div>
            
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/women.png"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold"> حميدي كوثر</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    مسؤولة الدراسات

                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                     
                      onclick="location.href='https://www.linkedin.com/in/kawther-hmidi-745a971b6/'"

                    >
                      <i class="fab fa-linkedin"></i></button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/abd_djalill.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">بن درف عبد الجليل </h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    مصمم 
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      onclick="location.href='https://www.linkedin.com/in/aedbendref'"

                    >
                      <i class="fab fa-linkedin"></i></button
                    >
                  </div>
                </div>
              </div>
            </div>
            
           
            
          </div>
        </div>
      </section>
      <section class="pb-20 relative block bg-gray-900 font-Changa">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-gray-900 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div id="scrollEmail" class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white" >تواصل معنا و شاركنا</h2>
              
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 id="idEmail" class="text-xl mt-5 font-semibold text-white">
                خبرة
              </h6>
              <p class="mt-2 mb-4 text-gray-500">
              في خبرتك لديك الكثير لتقوله لنا .. شاركنا رأيك
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                ملاحظة
              </h5>
              <p class="mt-2 mb-4 text-gray-500">
                فالإنسان غير معصوم من الخطأ فشاركنا ملاحظاتك لتحسين هذه التجربة .. سنكون مسرورين بذلك 
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">فكرة</h5>
              <p class="mt-2 mb-4 text-gray-500">
                سنكون الفريق الذي تبحث عنه ونسعى لتطبيق أفكاركم المذهلة 
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <EmailJs/>
      
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import EmailJs from "../components/EmailJs.vue";

export default {
  name: "landing-page",
  components: {
    NavbarComponent,
    FooterComponent,
    EmailJs,
    
},
 }
</script>
